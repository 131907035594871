import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

export default function PrimaryButton({
    className = '',
    disabled,
    children,
    ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            {...props}
            className={clsx(
                'inline-flex items-center px-4 py-2 bg-gradient-to-r from-mw-orange to-mw-magenta hover:from-mw-yellow hover:to-mw-magenta focus:ring-offset-black transition-all scale-100 hover:scale-105 rounded-[30px] font-semibold text-xs text-mw-white tracking-widest focus:bg-orange-700 active:bg-orange-900 ease-in-out duration-300',
                disabled ? 'opacity-25' : 'opacity-100',
                className
            )}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
